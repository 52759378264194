import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    ListItemText as MuiListItemText,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IProductVariant } from "../../types";
import { ProductStatus, productStatusByEnum } from "../../utils/enums";

const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
        min-width: 700px;
    }
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const StyledDialogTitle = styled(DialogTitle)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 0px;
`;

const StyledDialogActions = styled(DialogActions)`
    padding: ${({ theme }) => theme.spacing(1, 3, 3, 3)};
`;

const Cancel = styled(Close)`
    size: 24px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        size: 12px;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const SelectField = styled(Select)`
    height: 40px;
`;

const ListItemText = styled(MuiListItemText)`
    text-transform: capitalize;
`;

const TextFieldWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 100%;
`;

const SectionTitle = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    color: #626262;

    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Section = styled("div")``;

const Content = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const StyledJsonTextField = styled(TextField)`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    line-height: 1.5;

    padding: ${({ theme }) => theme.spacing(1)};
    background-color: #f5f5f5;
`;

const statuses = Object.keys(productStatusByEnum);

const validationSchema = Yup.object({
    title: Yup.string().min(1).required("Please enter a valid variant name"),
    shopifyVariantId: Yup.string()
        .min(1)
        .required("Please enter a valid shopify variant identifier"),
    price: Yup.number()
        .integer()
        .required("Please enter a selling price for the variant"),
    shippingPrice: Yup.number()
        .integer()
        .required("Please enter a shipping price for the variant"),
    mrp: Yup.number()
        .integer()
        .moreThan(0, "MRP must be greater than 0")
        .required("Please enter a MRP for the variant"),
    sku: Yup.string().required(),
    position: Yup.number().integer().required(),
    status: Yup.number()
        .integer()
        .oneOf([
            ProductStatus.ARCHIVED,
            ProductStatus.DRAFT,
            ProductStatus.PUBLISHED,
        ])
        .required(),
});

export interface ICreateProductVariantSchema
    extends Omit<IProductVariant, "meta"> {
    meta: string;
}

export interface IVariantDialogProps {
    variants: ICreateProductVariantSchema[];
    variant: ICreateProductVariantSchema | null;
    open: boolean;
    onClose: () => void;
    onUpdateVariant: (
        variant: ICreateProductVariantSchema,
        index: number,
    ) => void;
    selectedVariantPosition: number | null;
    productId: string;
    createNewProduct: boolean;
}

export const VariantDialog: FunctionComponent<IVariantDialogProps> = (
    props: IVariantDialogProps,
): ReactElement => {
    const {
        variant,
        variants,
        open,
        onClose,
        onUpdateVariant,
        selectedVariantPosition,
        createNewProduct,
    } = props;
    const create = variant === null;

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            id: variant?.id ?? `${variants.length + 1}`,
            title: variant?.title ?? "",
            shopifyVariantId: variant?.shopifyVariantId ?? "",
            price: variant?.price ?? 0,
            shippingPrice: variant?.shippingPrice ?? 0,
            mrp: variant?.mrp ?? 0,
            sku: variant?.sku ?? "",
            productId: variant?.productId ?? "",
            position: variant?.position ?? variants.length + 1,
            status: variant?.status ?? ProductStatus.DRAFT,
            meta: variant?.meta ?? "{}",
        },
        onSubmit: (values0) => {
            if (selectedVariantPosition !== null) {
                onUpdateVariant(values0, selectedVariantPosition);
            }

            if (selectedVariantPosition === null && createNewProduct) {
                onUpdateVariant(values0, variants.length);
            }
            onClose();
        },
        validationSchema,
    });

    const handleSaveVariant = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <StyledDialog
            maxWidth={"md"}
            disableScrollLock={true}
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>
                <Title>{create ? "Create Variant" : `Edit Variant`}</Title>
                <IconButton onClick={onClose}>
                    <Cancel />
                </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                {/* General */}
                <Section>
                    <SectionTitle>General</SectionTitle>
                    <Content>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Title"
                                        required={true}
                                        fullWidth={true}
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.title) &&
                                            touched.title
                                        }
                                        helperText={
                                            touched.title && errors.title
                                        }
                                    />
                                </TextFieldContainer>
                            </Row>
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Shopify Variant Identifier"
                                        fullWidth={true}
                                        required={true}
                                        name="shopifyVariantId"
                                        value={values.shopifyVariantId}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.shopifyVariantId) &&
                                            touched.shopifyVariantId
                                        }
                                        helperText={
                                            touched.shopifyVariantId &&
                                            errors.shopifyVariantId
                                        }
                                    />
                                </TextFieldContainer>
                                <TextFieldContainer>
                                    <FormControl size="small" fullWidth={true}>
                                        <InputLabel>Status</InputLabel>
                                        <SelectField
                                            label="Status"
                                            fullWidth={true}
                                            required={true}
                                            name="status"
                                            size="small"
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.status) &&
                                                touched.status
                                            }
                                        >
                                            {statuses.map((status) => (
                                                <MenuItem
                                                    key={status}
                                                    value={status}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            productStatusByEnum[
                                                                Number(status)
                                                            ]
                                                        }
                                                    />
                                                </MenuItem>
                                            ))}
                                        </SelectField>
                                        {Boolean(errors.status) &&
                                            touched.status && (
                                                <FormHelperText>
                                                    {errors.status}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </TextFieldContainer>
                            </Row>
                        </TextFieldWrapper>
                    </Content>
                </Section>

                {/* Inventory */}
                <Section>
                    <SectionTitle>Inventory</SectionTitle>
                    <Content>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="SKU"
                                        fullWidth={true}
                                        required={true}
                                        name="sku"
                                        value={values.sku}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.sku) && touched.sku
                                        }
                                        helperText={touched.sku && errors.sku}
                                    />
                                </TextFieldContainer>
                            </Row>
                        </TextFieldWrapper>
                    </Content>
                </Section>

                {/* Pricing */}
                <Section>
                    <SectionTitle>Pricing</SectionTitle>
                    <Content>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Selling Price"
                                        fullWidth={true}
                                        required={true}
                                        name="price"
                                        value={values.price}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.price) &&
                                            touched.price
                                        }
                                        helperText={
                                            touched.price && errors.price
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TextFieldContainer>
                                <TextFieldContainer>
                                    <TextField
                                        label="MRP"
                                        fullWidth={true}
                                        required={true}
                                        name="mrp"
                                        value={values.mrp}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.mrp) && touched.mrp
                                        }
                                        helperText={touched.mrp && errors.mrp}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TextFieldContainer>
                            </Row>
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Shipping Price"
                                        fullWidth={true}
                                        required={true}
                                        name="shippingPrice"
                                        value={values.shippingPrice}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.shippingPrice) &&
                                            touched.shippingPrice
                                        }
                                        helperText={
                                            touched.shippingPrice &&
                                            errors.shippingPrice
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TextFieldContainer>
                                <TextFieldContainer />
                            </Row>
                        </TextFieldWrapper>
                    </Content>
                </Section>

                {/* Metadata */}
                <Section>
                    <SectionTitle>Metadata</SectionTitle>
                    <Content>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextFieldWrapper>
                                        <StyledJsonTextField
                                            required={true}
                                            label="Metadata"
                                            multiline={true}
                                            rows={12}
                                            fullWidth={true}
                                            name="meta"
                                            value={values.meta}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.meta) &&
                                                touched.meta
                                            }
                                            helperText={
                                                touched.meta && errors.meta
                                            }
                                        />
                                    </TextFieldWrapper>
                                </TextFieldContainer>
                            </Row>
                        </TextFieldWrapper>
                    </Content>
                </Section>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button variant="outlined" color="error" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    variant="contained"
                    onClick={handleSaveVariant}
                >
                    Save
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};
