import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IProduct } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface IUpdateProductVariantParameters {
    id: string;
    productId: string;
    title: string;
    shopifyVariantId: string;
    price: number;
    mrp: number;
    shippingPrice: number;
    sku: string;
    position: number;
    status: number;
    meta: Record<string, unknown>;
}

export const useUpdateProductVariant = (
    invalidQueryKeys: string | string[],
): UseMutationResult<IProduct, unknown, IUpdateProductVariantParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<IProduct, unknown, IUpdateProductVariantParameters>({
        mutationFn: async (parameters: IUpdateProductVariantParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update product variant without authorization token.",
                );
            }

            return await api.Products.updateVariant({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
